import ActiveDraggableBlockMixin from '../../mixins/ActiveDraggableBlockMixin.js';
import ActiveDropzoneMixin from '../../mixins/ActiveDropzoneMixin.js';
import EditorEventMixin from '../../mixins/EditorEventMixin.js';
import PreviewHelperMixin from "../../mixins/PreviewHelperMixin";

Vue.asyncComponent('bp-block', {
    mixins: [ActiveDraggableBlockMixin, ActiveDropzoneMixin, EditorEventMixin, PreviewHelperMixin],
    data: function() {
        return {
            maxCols: 12,
            showOptions: false,
            editTitle: false,
            localTitle: this.title,
            style: {
                '--offset': this.offset,
                '--colspan': this.colspan
            },
        }
    },
    props: {
        title: {
            type: String,
        },
        titleDefault: {
            type: String,
            required: true,
        },
        id: {
            required: true,
        },
        blocks: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        allowsChildren: {
            type: Boolean,
        },
        colspan: {
            type: Number,
            required: true,
        },
        offset: {
            type: Number,
            required: false
        },
        isHidden: {
            type: Boolean,
            required: false,
            default: false
        },
        isEndOfRow: {
            type: Boolean,
            required: false,
            default: false
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        previewMode: {
            type:  Boolean,
            required: false,
            default: false
        },
        blocksActiveGuides: {
            type: Array,
            default: () => []
        },
        settings: {
            type: Object,
        }
    },
    watch: {
        localTitle() {
            this.$emit('blockPropertyChanged', {blockId: this.id, title: this.localTitle});
        }
    },
    computed: {
        /**
         * Here we check if a custom title is set otherwise we return the default title
         * @returns {string|*|(function(): string)}
         */
        currentTitle() {
            if ( this.title ) {
                return this.title;
            }

            return this.titleDefault;
        },
        /**
         * Check if the current item is active
         * @returns {boolean}
         */
        isActive() {
            return this.activeBlockLocal && this.id === this.activeBlockLocal.id;
        },
        /**
         * Check if the current items dropzone is active
         * @returns {boolean}
         */
        dropzoneActive() {
            return this.getActiveDropzone() === this.id;
        },
    },
    methods: {
        /**
         * When we start draging we keep the block id from the item that is being dragged
         * @param e
         * @param block
         */
        startDrag(e) {
            // check if no other element is dragging
            // this check is needed beceause when you drag an child element de drag e of the parent will be triggerd
            if (e.target !== this.$refs.draggable) {
                return;
            }

            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.effectAllowed = 'move';

            this.setActiveBlock(this.blockData());
            this.$emit('dragstart', this.blockData());
        },
        endDrag() {
            this.setActiveBlock(null);
            this.$emit('dragend', this.blockData());
        },
        /**
         * The current block is resized or the offset is changed
         * @param $event
         */
        currentBlockDimensionsChanged($event) {
            this.blockDimensionsChanged( {...$event, blockId: this.id});
        },
        /**
         * A child block dimensions has changed notify the parents
         * @param $event
         */
        blockDimensionsChanged($event) {
            this.$emit('blockDimensionsChanged', $event)
        },
        /**
         * The data of the current block
         * @returns {{colspan: Number, offset: Number, blocks: *[], allowsChildren: Boolean, id: *, title: String}}
         */
        blockData() {
            return {id: this.id, title: this.title, offset: this.offset, colspan: this.colspan, blocks: this.blocks, allowsChildren: this.allowsChildren}
        },
        /**
         * We set the width, Height and possible offfset for the current block
         * For this we will look in the refering element in de preview html
         */
        setDimensionsForPreviewMode() {
            const $el = this.getBlockElementById(this.id);
            this.style =  {
                '--block-height': $el.getBoundingClientRect().height + 'px',
                '--block-width': $el.getBoundingClientRect().width + 'px',
            };

            // afther the width and height is set we can check if there is stille any offset we need to add
            this.$nextTick(() => {
                if ($el.dataset.blockOffset !== undefined) {
                    this.style = {
                        '--block-offset-left': (this.previewIframe.getBoundingClientRect().left + ($el.getBoundingClientRect().left - this.$el.getBoundingClientRect().left)) + 'px',
                        ... this.style
                    };
                }
            })
        },
        /**
         * Show the current block options
         */
        showCurrentBlockOptions() {
            this.$emit('showBlockOptions', this.id);
        },
        /**
         * We want to start editing the title
         */
        startEditTitle() {
            this.editTitle = true;

            // we want to focus on the input field when visisble
            this.$nextTick(() => {
                this.$refs.titleField.focus();
            })
        },
        /**
         * We want to stop editing the title
         */
        stopEditTitle() {
            this.editTitle = false;
        }
    },
    mounted() {
        // if we are in preview mode we will need to set ower dimensions
        if (this.previewMode) {
            this.previewIframe.contentWindow.addEventListener('resize', this.setDimensionsForPreviewMode,{capture: true});
            this.previewIframe.addEventListener('load', this.setDimensionsForPreviewMode,{capture: true});
        }
    }
}, 'blueprints/editor/bp-block.html');